/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hi, and thanks for checking out this still very new attempt at writing!"), "\n", React.createElement(_components.p, null, "As is the way with all things that devs lay their hands on, this has to start with a grubby little \"hello world\"."), "\n", React.createElement(_components.p, null, "Having had a vague idea for a domain and a passion for building things in the cloud, I thought I'd create a space where I can post some tutorials that will hopefully help people out. Because I'm a dev, I had to massively overcomplicate that process by building something myself, and god forbid any of my more front-end oriented friends see the bits of string and paperclips that loosely hold this poor thing together."), "\n", React.createElement(_components.p, null, "That all being said, it was a good learning opportunity which hopefully helps set a decent tone for whatever it is I'm intending to do here. My main goals for this space are:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Help people get started in the cloud (specifically with AWS at the moment)"), "\n", React.createElement(_components.li, null, "Amplify interesting topics for people who are already elbow-deep in the cloud"), "\n", React.createElement(_components.li, null, "Use it as an opportunity to force myself to learn things in ", React.createElement(_components.a, {
    href: "https://effectiviology.com/protege-effect-learn-by-teaching/"
  }, "a way that's comfortable to me")), "\n", React.createElement(_components.li, null, "Occasionally yell / gently snark at the cloud"), "\n"), "\n", React.createElement(_components.p, null, "I'll probably get around to adding (and documenting the process for) some form of comments, but for now if you have questions about anything I throw up here, feel free to reach out to me on my ", React.createElement(_components.a, {
    href: "https://twitter.com/J0shSimpson"
  }, "Twitter")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
